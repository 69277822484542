import React, { useState } from "react";
import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Text,
  Button,
  Box,
  Anchor,
  Notification,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import { useMediaQuery } from "@mantine/hooks";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const recaptchaRef = React.createRef();

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validate: {
      name: (value) =>
        value.trim().length < 2 ? "Name must be at least 2 characters" : null,
      email: (value) =>
        !/^\S+@\S+$/.test(value) ? "Invalid email address" : null,
      subject: (value) =>
        value.trim().length === 0 ? "Subject is required" : null,
      message: (value) =>
        value.trim().length === 0 ? "Message is required" : null,
    },
  });

  const onSubmit = async (values) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setLoading(true);

    try {
      const response = await axios.post("http://localhost:8086/submitform", {
        ...values,
        "g-recaptcha-response": recaptchaValue,
      });
      setResult(response.data.responseCode === 0 ? "success" : "error");
    } catch (error) {
      setResult("error");
    } finally {
      setLoading(false);
    }
  };

  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box
      id="contact"
      sx={{ minHeight: "400px", maxWidth: 800, margin: "auto", padding: 20 }}
    >
      <Box
        w={matches ? "700px" : "100%"}
        mx="auto"
        mb={25}
        mt={25}
        sx={(theme) => ({
          background: "#7C0144",
          color: "white",
          textAlign: "center",
          padding: 5,
          borderRadius: "5px",
        })}
      >
        <Title>Get in touch with Tsnatee.</Title>
      </Box>

      <Text mb={25} fw={600} align="center">
        Wil je direct bellen of mailen? Mail naar{" "}
        <Anchor fw={700} c="#7C0144" href="mailto:info@tsnatee.com">
          info@tsnatee.com
        </Anchor>{" "}
        of bel mij via{" "}
        <Anchor fw={700} c="#7C0144" href="tel:+31653107363">
          +31653107363
        </Anchor>
      </Text>

      {loading && (
        <Notification loading title="Sending your message..." color="yellow">
          Our contact form is doing its thing. Soon, your message will be
          delivered.
        </Notification>
      )}

      {result === "success" && (
        <Notification title="Message sent!" color="green">
          Thank you for reaching out. Tsnatee will be in touch soon.
        </Notification>
      )}

      {result === "error" && (
        <Notification title="Something went wrong..." color="red">
          Unfortunately, something went wrong. Please try again later.
        </Notification>
      )}

      {!loading && !result && (
        <form onSubmit={form.onSubmit(onSubmit)}>
          <SimpleGrid cols={2} breakpoints={[{ maxWidth: 800, cols: 1 }]}>
            <TextInput
              label="Name"
              placeholder="Your name"
              {...form.getInputProps("name")}
            />
            <TextInput
              label="Email"
              placeholder="Your email"
              {...form.getInputProps("email")}
            />
          </SimpleGrid>

          <TextInput
            label="Subject"
            placeholder="Subject"
            mt="md"
            {...form.getInputProps("subject")}
          />
          <Textarea
            label="Message"
            placeholder="Your message"
            mt="md"
            {...form.getInputProps("message")}
          />

          <Box mt="md">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdMJF4pAAAAAEcjHC0Q4s5tbNZPvUmiV1mB88UN"
            />
          </Box>

          <Group position="center" mt="xl" m={100}>
            <Button
              sx={{
                "&:hover": {
                  backgroundColor: "#7b0144",
                },
              }}
              bg="#fda701"
              type="submit"
            >
              Send message
            </Button>
          </Group>
        </form>
      )}
    </Box>
  );
};

export default ContactForm;
