import React from "react";
import {
  Footer,
  Text,
  Group,
  Center,
  Anchor,
  Box,
  SimpleGrid,
  Title,
  Stack,
  ActionIcon,
  Flex,
  TextInput,
  Button,
  Input,
  UnstyledButton,
} from "@mantine/core";

import Newsletter from "./NewsLetter";

import {
  BrandLinkedin,
  BrandInstagram,
  BrandYoutube,
} from "tabler-icons-react";

function FooterComponent() {
  return (
    <Footer
      bg="#7b0144"
      p="xs"
      mx="auto"
      sx={(theme) => ({
        borderTop: "4px solid transparent",
        borderImage: "linear-gradient(to right, #7b0144, #fada01);",
        borderImageSlice: 1,
      })}
    >
      <SimpleGrid
        breakpoints={[
          { minWidth: "sm", cols: 1 },
          { minWidth: "md", cols: 2 },
        ]}
        spacing="xl"
        mt="1.5rem"
        w="80%"
        mx="auto"
      >
        <Stack gap="xl">
          <Title
            order={2}
            c="white"
            size="1.4rem"
            fw="600"
            color="black"
            weight="800"
          >
            Blijf op de hoogte
          </Title>
          <Text c="white">
            Wil je ook op de hoogte blijven van de aankomende events van
            Tsnatee, hoogtepunten, kortingen en een kijkje achter de schermen?
            Laat dan hier je gegevens achter en ontvang 1 tot 2 keer per maand
            een leuke nieuwsbrief!
          </Text>
          <Newsletter />
        </Stack>
        <Box>
          <Stack gap="xs">
            <Title
              order={2}
              c="white"
              size="1.4rem"
              fw="600"
              color="black"
              weight="800"
            >
              Volg Mij
            </Title>
            <Text c="white"></Text>

            <Group c="white">
              <UnstyledButton
                component="a"
                target="_blank"
                c="white"
                href="https://linkedin.com/in/tsnatee-elisa"
              >
                <BrandLinkedin strokeWidth={1.5} size="1.8rem" />
              </UnstyledButton>
              <UnstyledButton
                component="a"
                target="_blank"
                c="white"
                href="https://www.instagram.com/tsnatee"
              >
                <BrandInstagram strokeWidth={1.5} c="white" size="1.8rem" />
              </UnstyledButton>
              <UnstyledButton
                component="a"
                target="_blank"
                c="white"
                href="https://www.youtube.com/@TsnateeElisa"
              >
                <BrandYoutube strokeWidth={1.5} c="white" size="1.8rem" />
              </UnstyledButton>
            </Group>
            <Text c="white">
              <Text span inline fw={700}>
                Email:
              </Text>{" "}
              <Anchor c="white" href="mailto:info@tsnatee.com">
                info@tsnatee.com
              </Anchor>
            </Text>
            <Text c="white">
              <Text span inline fw={700}>
                Telefoon:
              </Text>{" "}
              <Anchor c="white" href="tel:+31653107363">
                +31653107363
              </Anchor>
            </Text>
          </Stack>
        </Box>
      </SimpleGrid>
      <Box w="80%" mx="auto" my="1rem">
        <Text fz="xs" pt={5} c="white">
          Copyright © 2025{" "}
          <Text span inline fw={700}>
            Tsnatee.com
          </Text>
        </Text>
      </Box>
    </Footer>
  );
}

export default FooterComponent;
